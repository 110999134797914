import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faStar as farStar,
  faMessage as farMessage,
  faComments as farComments,
  faHeart as farHeart,
  faCalendarDays as farCalendarDays
  // faBagShopping as farBagShopping,
  // faGameBoard as farGameBoard,
  // faCandleHolder as farCandleHolder
} from '@fortawesome/free-regular-svg-icons'

import {
  faFacebookF as fabFacebookF,
  faFacebookMessenger as fabFacebookMessenger,
  faInstagram as fabInstagram,
  faTelegram as fabTelegram,
  faYoutube as fabYoutube,
  faTwitter as fabTwitter,
  faXTwitter as fabXTwitter,
  faSkype as fabSkype,
  faWhatsapp as fabWhatsapp,
  faViber as fabViber,
  faGoogle as fabGoogle,
  faApple as fabApple,
  faPlaystation as fabPlaystation,
  faXbox as fabXbox,
  faTiktok as fabTiktok,
  faWindows as fabWindows,
  faAndroid as fabAndroid,
  faGooglePay as fabGooglePay,
  faApplePay as fabApplePay
} from '@fortawesome/free-brands-svg-icons'

import {
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faLocationPin as fasLocationPin,
  faLocationDot as fasLocationDot,
  faUser as fasUser,
  faInfo as fasInfo,
  faImage as fasImage,
  // faNoteSticky as fasNoteSticky,
  faQuestion as fasQuestion,
  faAddressBook as fasAddressBook,
  faBook as fasBook,
  faStore as fasStore,
  faMoneyBill as fasMoneyBill,
  faMoneyCheckDollar as fasMoneyCheckDollar,
  faCashRegister as fasCashRegister,
  // faPersonCarry as fasPersonCarry,
  faMobile as fasMobile,
  faMobileScreen as fasMobileScreen,
  // faPhoneOffice as fasPhoneOffice,
  faClock as fasClock,
  faSimCard as fasSimCard,
  faPhone as fasPhone,
  // faPhoneRotary as fasPhoneRotary,
  faPhoneVolume as fasPhoneVolume,
  faScaleBalanced as fasScaleBalanced,
  faBagShopping as fasBagShopping,
  faCartShopping as fasCartShopping,
  faCartPlus as fasCartPlus,
  faMagnifyingGlass as fasMagnifyingGlass,
  faBars as fasBars,
  faFilter as fasFilter,
  faSliders as fasSliders,
  faFire as fasFire,
  faArrowDownWideShort as fasArrowDownWideShort,
  faArrowDown91 as fasArrowDown91,
  faArrowDown19 as fasArrowDown19,
  faCirclePlay as fasCirclePlay,
  faTruck as fasTruck,
  faWallet as fasWallet,
  faChartPie as fasChartPie,
  faAward as fasAward,
  faScrewdriverWrench as fasScrewdriverWrench,
  faGuitar as fasGuitar,
  faCompactDisc as fasCompactDisc,
  faRecordVinyl as fasRecordVinyl,
  // faChairOffice as fasChairOffice,
  faVrCardboard as fasVrCardboard,
  // faHeadVr as fasHeadVr,
  faMicrochip as fasMicrochip,
  faArrowRotateLeft as fasArrowRotateLeft,
  faCheck as fasCheck,
  faCircleCheck as fasCircleCheck,
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faEnvelope as fasEnvelope,
  faNewspaper as fasNewspaper,
  faPenRuler as fasPenRuler,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faStar as fasStar,
  faMessage as fasMessage,
  faComments as fasComments,
  faHeart as fasHeart,
  faMinus as fasMinus,
  faPlus as fasPlus,
  faTrash as fasTrash,
  faXmark as fasXmark,
  faCircleXmark as fasCircleXmark,
  faCircleInfo as fasCircleInfo,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faCreditCard as fasCreditCard,
  faPen as fasPen,
  faBellSlash as fasBellSlash,
  faHeadset as fasHeadset,
  // faSnooze as fasSnooze,
  faLaptop as fasLaptop,
  faDesktop as fasDesktop,
  // faDesktopAlt as fasDesktopAlt,
  faPlug as fasPlug,
  faFan as fasFan,
  // faFanTable as fasFanTable,
  faPrint as fasPrint,
  // faScannerImage as fasScannerImage,
  // faShredder as fasShredder,
  faCopy as fasCopy,
  // faRouter as fasRouter,
  faTv as fasTv,
  faEthernet as fasEthernet,
  // faBrackets as fasBrackets,
  // faProjector as fasProjector,
  faTablet as fasTablet,
  faTabletScreenButton as fasTabletScreenButton,
  faSquarePen as fasSquarePen,
  faSquare as fasSquare,
  faBatteryFull as fasBatteryFull,
  faBatteryThreeQuarters as fasBatteryThreeQuarters,
  faBatteryHalf as fasBatteryHalf,
  faBatteryQuarter as fasBatteryQuarter,
  faBatteryEmpty as fasBatteryEmpty,
  faCarBattery as fasCarBattery,
  faServer as fasServer,
  // faWatch as fasWatch,
  // faWatchFitness as fasWatchFitness,
  faHelicopter as fasHelicopter,
  // faCameraHome as fasCameraHome,
  faGlobe as fasGlobe,
  faLocationArrow as fasLocationArrow,
  faCamera as fasCamera,
  faHeadphonesSimple as fasHeadphonesSimple,
  // faWasher as fasWasher,
  // faDryer as fasDryer,
  // faRefrigerator as fasRefrigerator,
  // faOven as fasOven,
  faHouse as fasHouse,
  // faHouse as fasHouse,
  faHeartPulse as fasHeartPulse,
  faTent as fasTent,
  faGamepad as fasGamepad,
  faChild as fasChild,
  faChargingStation as fasChargingStation,
  faShirt as fasShirt,
  faCarRear as fasCarRear,
  faPuzzlePiece as fasPuzzlePiece,
  faCouch as fasCouch,
  faMicrophone as fasMicrophone,
  faMicrophoneSlash as fasMicrophoneSlash,
  faHashtag as fasHashtag,
  faPalette as fasPalette,
  faStopwatch as fasStopwatch,
  faMapPin as fasMapPin,
  faMap as fasMap,
  faScrewdriver as fasScrewdriver,
  // faTelescope as fasTelescope,
  faVideo as fasVideo,
  // faSpeakers as fasSpeakers,
  // faSpeaker as fasSpeaker,
  // faComputerSpeaker as fasComputerSpeaker,
  // faMp3Player as fasMp3Player,
  // faWaveSine as fasWaveSine,
  faMotorcycle as fasMotorcycle,
  faBicycle as fasBicycle,
  faDumbbell as fasDumbbell,
  faPersonHiking as fasPersonHiking,
  faShapes as fasShapes,
  faSchool as fasSchool,
  faCakeCandles as fasCakeCandles,
  // faVacuum as fasVacuum,
  // faAirConditioner as fasAirConditioner,
  faTemperatureLow as fasTemperatureLow,
  faBuildingColumns as fasBuildingColumns,
  faPercent as fasPercent,
  faIndustry as fasIndustry,
  // faMicrowave as fasMicrowave,
  faBlender as fasBlender,
  faBreadSlice as fasBreadSlice,
  faIceCream as fasIceCream,
  // faFrenchFries as fasFrenchFries,
  // faMugTea as fasMugTea,
  // faCoffeePot as fasCoffeePot,
  // faShishKebab as fasShishKebab,
  // faSteak as fasSteak,
  // faSandwich as fasSandwich,
  // faToothbrush as fasToothbrush,
  // faAppleCrate as fasAppleCrate,
  // faHeat as fasHeat,
  // faHumidity as fasHumidity,
  // faFireplace as fasFireplace,
  // faGripLinesVertical as fasGripLinesVertical,
  // faHorizontalRule as fasHorizontalRule,
  faWind as fasWind,
  faUtensils as fasUtensils,
  faThermometer as fasThermometer,
  faScissors as fasScissors,
  faTag as fasTag,
  faTags as fasTags,
  faFlag as fasFlag,
  faCopyright as fasCopyright,
  // faUserMusic as fasUserMusic,
  faAtom as fasAtom,
  faHandPointRight as fasHandPointRight,
  faBarsStaggered as fasBarsStaggered,
  faHdd as fasHdd,
  faBroom as fasBroom,
  faUpDownLeftRight as fasUpDownLeftRight,
  faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter,
  faTableCells as fasTableCells,
  faMemory as fasMemory,
  faRulerCombined as fasRulerCombined,
  faTape as fasTape,
  faBox as fasBox,
  faBoxesStacked as fasBoxesStacked,
  faRulerHorizontal as fasRulerHorizontal,
  faRulerVertical as fasRulerVertical,
  faRuler as fasRuler,
  faWeightHanging as fasWeightHanging,
  faWeightScale as fasWeightScale,
  // faBadge as fasBadge,
  faRibbon as fasRibbon,
  faDog as fasDog,
  // faLampDesk as fasLampDesk,
  faSocks as fasSocks,
  faCookie as fasCookie,
  faArrowsRotate as fasArrowsRotate,
  faTruckArrowRight as fasTruckArrowRight,
  faGripVertical as fasGripVertical,
  faGrip as fasGrip,
  faIdCardClip as fasIdCardClip,
  faAddressCard as fasAddressCard,
  faKey as fasKey,
  faArrowRightFromBracket as fasArrowRightFromBracket,
  faSquareCheck as fasSquareCheck,
  faTriangleExclamation as fasTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

library.add(
  farStar,
  farMessage,
  farComments,
  farHeart,
  farCalendarDays,
  // farBagShopping,
  // farGameBoard,
  // farCandleHolder,

  fabFacebookF,
  fabFacebookMessenger,
  fabInstagram,
  fabTelegram,
  fabYoutube,
  fabTwitter,
  fabXTwitter,
  fabSkype,
  fabWhatsapp,
  fabViber,
  fabGoogle,
  fabApple,
  fabPlaystation,
  fabXbox,
  fabTiktok,
  fabWindows,
  fabAndroid,
  fabGooglePay,
  fabApplePay,

  fasAngleLeft,
  fasAngleRight,
  fasLocationPin,
  fasLocationDot,
  fasUser,
  fasInfo,
  fasImage,
  // fasNoteSticky,
  fasQuestion,
  fasAddressBook,
  fasBook,
  fasStore,
  fasMoneyBill,
  fasMoneyCheckDollar,
  fasCashRegister,
  // fasPersonCarry,
  fasMobile,
  fasMobileScreen,
  // fasPhoneOffice,
  fasClock,
  fasSimCard,
  fasPhone,
  // fasPhoneRotary,
  fasPhoneVolume,
  fasScaleBalanced,
  fasBagShopping,
  fasCartShopping,
  fasCartPlus,
  fasMagnifyingGlass,
  fasBars,
  fasFilter,
  fasSliders,
  fasFire,
  fasArrowDownWideShort,
  fasArrowDown91,
  fasArrowDown19,
  fasCirclePlay,
  fasTruck,
  fasWallet,
  fasChartPie,
  fasAward,
  fasScrewdriverWrench,
  fasGuitar,
  fasCompactDisc,
  fasRecordVinyl,
  // fasChairOffice,
  fasVrCardboard,
  // fasHeadVr,
  fasMicrochip,
  fasArrowRotateLeft,
  fasCheck,
  fasCircleCheck,
  fasAngleDown,
  fasAngleUp,
  fasEnvelope,
  fasNewspaper,
  fasPenRuler,
  fasChevronUp,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasStar,
  fasMessage,
  fasComments,
  fasHeart,
  fasMinus,
  fasPlus,
  fasTrash,
  fasXmark,
  fasCircleXmark,
  fasCircleInfo,
  fasEye,
  fasEyeSlash,
  fasCreditCard,
  fasPen,
  fasBellSlash,
  fasHeadset,
  // fasSnooze,
  fasLaptop,
  fasDesktop,
  // fasDesktopAlt,
  fasPlug,
  fasFan,
  // fasFanTable,
  fasPrint,
  // fasScannerImage,
  // fasShredder,
  fasCopy,
  // fasRouter,
  fasTv,
  fasEthernet,
  // fasBrackets,
  // fasProjector,
  fasTablet,
  fasTabletScreenButton,
  fasSquarePen,
  fasSquare,
  fasBatteryFull,
  fasBatteryThreeQuarters,
  fasBatteryHalf,
  fasBatteryQuarter,
  fasBatteryEmpty,
  fasCarBattery,
  fasServer,
  // fasWatch,
  // fasWatchFitness,
  fasHelicopter,
  // fasCameraHome,
  fasGlobe,
  fasLocationArrow,
  fasCamera,
  fasHeadphonesSimple,
  // fasWasher,
  // fasDryer,
  // fasRefrigerator,
  // fasOven,
  fasHouse,
  // fasHouse,
  fasHeartPulse,
  fasTent,
  fasGamepad,
  fasChild,
  fasChargingStation,
  fasShirt,
  fasCarRear,
  fasPuzzlePiece,
  fasCouch,
  fasMicrophone,
  fasMicrophoneSlash,
  fasHashtag,
  fasPalette,
  fasStopwatch,
  fasMapPin,
  fasMap,
  fasScrewdriver,
  // fasTelescope,
  fasVideo,
  // fasSpeakers,
  // fasSpeaker,
  // fasComputerSpeaker,
  // fasMp3Player,
  // fasWaveSine,
  fasMotorcycle,
  fasBicycle,
  fasDumbbell,
  fasPersonHiking,
  fasShapes,
  fasSchool,
  fasCakeCandles,
  // fasVacuum,
  // fasAirConditioner,
  fasTemperatureLow,
  fasBuildingColumns,
  fasPercent,
  fasIndustry,
  // fasMicrowave,
  fasBlender,
  fasBreadSlice,
  fasIceCream,
  // fasFrenchFries,
  // fasMugTea,
  // fasCoffeePot,
  // fasShishKebab,
  // fasSteak,
  // fasSandwich,
  // fasToothbrush,
  // fasAppleCrate,
  // fasHeat,
  // fasHumidity,
  // fasFireplace,
  // fasGripLinesVertical,
  // fasHorizontalRule,
  fasWind,
  fasUtensils,
  fasThermometer,
  fasScissors,
  fasTag,
  fasTags,
  fasFlag,
  fasCopyright,
  // fasUserMusic,
  fasAtom,
  fasHandPointRight,
  fasBarsStaggered,
  fasHdd,
  fasBroom,
  fasUpDownLeftRight,
  fasUpRightAndDownLeftFromCenter,
  fasTableCells,
  fasMemory,
  fasRulerCombined,
  fasTape,
  fasBox,
  fasBoxesStacked,
  fasRulerHorizontal,
  fasRulerVertical,
  fasRuler,
  fasWeightHanging,
  fasWeightScale,
  // fasBadge,
  fasRibbon,
  fasDog,
  // fasLampDesk,
  fasSocks,
  fasCookie,
  fasArrowsRotate,
  fasTruckArrowRight,
  fasGripVertical,
  fasGrip,
  fasIdCardClip,
  fasAddressCard,
  fasKey,
  fasArrowRightFromBracket,
  fasSquareCheck,
  fasTriangleExclamation
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
